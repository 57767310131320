import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Divider,
  CardMedia,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import skincheck from "../assets/skin-check6.png";
import eyeLift from "../assets/skin-check5.png";

interface Service {
  title: string;
  content: JSX.Element;
  id?: string;
  imageUrl?: string;
}

const services: Service[] = [
  {
    title: "Anti-Wrinkle Injections",
    imageUrl: eyeLift,
    id: "anti-wrinkle",
    content: (
      <Box px={4}>
        <Typography
          variant="body1"
          gutterBottom
          mb={4}
          sx={{ textAlign: "center" }}
        >
          Prices starting from $100 per area. Anti-wrinkle injections help to
          relax the muscle with results lasting between 2 - 4 months depending
          on the specific area treated.
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
          Areas that can be treated include
        </Typography>
        <ul>
          <li>Frown lines</li>
          <li>Crow's feet</li>
          <li>Forehead lines</li>
          <li>Bunny Scrunch</li>
        </ul>
      </Box>
    ),
  },
  {
    title: "Dermal Filler",
    id: "dermal-filler",
    content: (
      <Box px={7}>
        <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
          Prices starting from $495 per 1ml. Dermal fillers help restore lost
          volume or enhance what you already have. We use premium fillers here
          at Lotus Skin Clinic and Cosmetic Centre to give you the best and most
          importantly natural-looking results.
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ textAlign: "center", mt: 3 }}
        >
          Common areas to have treated include:
        </Typography>
        <ul>
          <li>Cheeks</li>
          <li>Lips</li>
          <li>Jawline</li>
          <li>Tear trough (under eyes)</li>
          <li>Marionette lines (the lines from the corners of your mouth)</li>
          <li>
            Nasolabial folds (the lines from the corners of your nose to the
            corners of your mouth)
          </li>
        </ul>
      </Box>
    ),
  },
  {
    title: "Non-Surgical Face Lifts",
    id: "face-lifts",
    content: (
      <Box
        px={7}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ textAlign: "center" }}
      >
        <Typography variant="body1" gutterBottom>
          One of the treatments that Dr El-Ghobashy performs is the Non-Surgical
          Facelift otherwise known as the "Lunchtime Facelift". This procedure
          has minimum downtime and is performed under a local anaesthetic in our
          clinic at Harbour Town Gold Coast.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Facial threading can give your face a natural lift and stimulate
          collagen production and help skin laxity. Perfect for both men and
          women.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you are looking for IMMEDIATE results that last, then look no
          further. Book a COMPLIMENTARY consultation today.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Blepharoplasty",
    id: "eye-lift",
    content: (
      <Box
        px={7}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ textAlign: "center" }}
      >
        <Typography variant="body1" gutterBottom>
          Discover a brighter, rejuvenated gaze with blepharoplasty. Commonly
          known as eyelid surgery, this transformative procedure is designed to
          enhance the appearance of the eyelids.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Whether you're seeking to address drooping upper eyelids or reduce
          under-eye puffiness, Dr. Gamal EL-Ghobashy can tailor the treatment to
          your unique needs. This procedure can help restore a refreshed and
          alert look to the eyes, boosting confidence and revitalizing your
          overall appearance.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you're ready to awaken your eyes to new, vibrant beauty, schedule a
          COMPLIMENTARY consultation with us today.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Microdermabrasion",
    id: "microdermabrasion",
    content: (
      <Box
        px={7}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ textAlign: "center" }}
      >
        <Typography variant="body1" gutterBottom>
          Microdermabrasions are great for all types of skin concerns and have
          no downtime. They work by removing dead skin cells and resurfacing the
          skin, revealing a smoother and clearer complexion.
        </Typography>
        <Typography variant="body1" gutterBottom>
          The treatment takes 30 minutes, and you have the option of adding on a
          peel for $40.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Mole or skin tag removal",
    id: "mole-removal",
    content: (
      <Box
        px={7}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ textAlign: "center" }}
      >
        <Typography variant="body1" gutterBottom>
          Starting from just $200, our skilled doctors at Lotus Skin Clinic
          offer surgical removal of unwanted non-cancerous skin tags or moles.
          With precision and expertise, we ensure safe and effective removal,
          helping you achieve smoother, clearer skin.
        </Typography>
      </Box>
    ),
  },
  {
    title: "Sunspot removal",
    id: "sunspot-removal",
    content: (
      <Box
        px={7}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
        sx={{ textAlign: "center" }}
      >
        <Typography variant="body1" gutterBottom>
          At Lotus Skin Clinic, we offer Efu-cal (vitamin D and Efudix), a
          combination cream of calcipotriol and fluorouracil, for treating solar
          keratoses. This treatment involves applying a thin smear of the cream
          to the affected area twice daily, following a specific schedule for
          different body parts. The cream boosts the skin&#39;s natural
          immunity, reducing solar keratoses and early skin cancers by up to
          87%.
        </Typography>
      </Box>
    ),
  },
];

interface ServiceCardProps {
  service: Service;
  expandedServiceId: string | null;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  expandedServiceId,
}) => {
  const [expanded, setExpanded] = useState(service.id === expandedServiceId);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        onClick={handleExpandClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }} id={service.id}>
          {service.title}
        </Typography>
        <IconButton>
          <ExpandMoreIcon
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          />
        </IconButton>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider sx={{ my: 2 }} />
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          {service.imageUrl !== undefined && (
            <img
              src={service.imageUrl}
              alt={service.title}
              style={{
                maxWidth: "50%",
                height: "auto",
                marginRight: "16px",
                borderRadius: "10px",
              }}
            />
          )}
          <Box sx={{ m: 2, borderRadius: "10px" }}>{service.content}</Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const DividerLine: React.FC = () => (
  <Box
    sx={{
      width: "50px",
      height: "3px",
      backgroundColor: "black",
      margin: "0 auto",
      my: 2,
    }}
  />
);

const ServicesPage: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const expandedServiceId = params.get("expand");

  return (
    <Box
      sx={{
        py: 15,
        backgroundColor: "#f0f0f0",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 8 }}>
        {/* Title Section */}
        <Box sx={{ mb: 8, textAlign: "center" }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Our Services
          </Typography>
          <DividerLine />
        </Box>

        {/* Skin Cancer Section */}
        <Grid item xs={12}>
          <Card
            sx={{
              mb: 6,
              display: "flex",
              flexDirection: { xs: "column", md: "column" },
              alignItems: "center",
              p: 2,
            }}
          >
            <CardContent sx={{ flex: 1 }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  textAlign: "center",
                }}
                id="skin-cancer"
              >
                Skin Cancer
              </Typography>
              <DividerLine />
              <Typography variant="body1" gutterBottom px={4}>
                Skin cancer is the most common form of cancer worldwide, yet it
                is often preventable and highly treatable when detected early.
                Regular skin checks and sun protection measures are essential
                for early detection and prevention.
              </Typography>
              <Divider sx={{ my: 2 }} />
            </CardContent>
            <CardContent sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={skincheck}
                  alt="Skin Cancer"
                  sx={{
                    minWidth: { md: "50%" },
                    height: "auto",
                    pl: 2,
                    pr: 2,
                    mr: 1,
                  }}
                />
                <Box display={"flex"} flexDirection={"column"} gap={2} px={2}>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Skin Check
                    </Typography>
                    <Divider />
                    <Typography variant="body1" gutterBottom>
                      During this check, the doctor meticulously inspects moles,
                      lesions, and other areas of concern to identify any
                      abnormalities or potential indications of skin cancer.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      If any suspicious areas are found, further evaluation or
                      treatment may be recommended.
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Skin Cancer Treatment
                    </Typography>
                    <Divider />
                    <Typography variant="body1" gutterBottom>
                      Typically, treatment is straightforward, involving
                      procedures such as a skin biopsy or cryotherapy which can
                      be done during your session.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      For more intricate surgical excisions, a separate
                      appointment will be scheduled.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box mt={4} px={4}>
                <Typography
                  textAlign={"center"}
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  After Care
                </Typography>
                <DividerLine />

                <Typography variant="body1" gutterBottom>
                  <strong>Removal of sutures –</strong> If sutures are placed
                  during your appointment at Lotus Skin Clinic, we prioritize
                  your convenience by scheduling a removal appointment on the
                  same day.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Post-operative complications -</strong> If you
                  experience any concerns such as bleeding or discomfort after
                  your procedure, we encourage you to reach out to us
                  immediately. Our post-operative care protocol ensures prompt
                  attention to your needs. You can come directly to our clinic,
                  and we will provide the necessary treatment as soon as
                  possible to address any complications and ensure your comfort
                  and recovery.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Cosmetics Section */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "black", textAlign: "center" }}
                id="cosmetics"
              >
                Cosmetics
              </Typography>
              <DividerLine />
              <Typography variant="body1" gutterBottom>
                Lotus skin Clinic and Cosmetic Centre offer both injectable and
                surgical anti-ageing treatments. Check out some of our services:
              </Typography>
            </Grid>

            {/* Cosmetic Services */}
            <Grid container spacing={2}>
              {services.map((service, index) => (
                <Grid item xs={12} key={index}>
                  <ServiceCard
                    service={service}
                    expandedServiceId={expandedServiceId}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ServicesPage;
