import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Typography,
  Box,
  Stack,
  MenuItem,
  Menu,
  useTheme,
  useMediaQuery,
  colors,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import lotusLogo from "../assets/lotusLogo.png";
import MenuIcon from "@mui/icons-material/Menu";

function NavigationBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [aboutAnchorEl, setAboutAnchorEl] = useState<null | HTMLElement>(null);
  const [servicesAnchorEl, setServicesAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up("md"));

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:600px) and (max-width:960px)"
  );

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAboutMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAboutAnchorEl(event.currentTarget);
  };

  const handleServicesMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setServicesAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAboutAnchorEl(null);
    setServicesAnchorEl(null);
  };

  const handleMenuClick = (path: string, sectionId?: string) => {
    navigate(path);
    if (sectionId) {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 100;
        if (sectionElement) {
          const targetScroll = sectionElement.offsetTop - offset;
          window.scrollTo({
            top: targetScroll,
            behavior: "smooth",
          });
        }
      }, 0);
    }
    handleMenuClose();
  };

  const handleMenuLinkClick = (
    event: React.MouseEvent<HTMLElement>,
    path: string
  ) => {
    event.preventDefault();
    navigate(path);
    handleMenuClose();
  };

  function PhoneMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuClick = (path: string) => {
      navigate(path);
      handleClose();
    };

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MenuIcon sx={{ color: "black" }} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleMenuClick("/")}>Home</MenuItem>
          <MenuItem onClick={() => handleMenuClick("/services")}>
            Services
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("/contact")}>
            Contact Us
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick("/about-us")}>
            About Us
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
          transform: isScrolled ? "translateY(-15%)" : "translateY(0)",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: { sm: "0", md: "2em" },
              flexShrink: 0,
              borderRadius: "999px",
              backdropFilter: "blur(24px)",
              maxHeight: isScrolled ? "5em" : "7em",
              border: "1px solid",
              borderColor: "divider",
              background: "rgba(255, 255, 255, 0.5)",
              transition: "all 0.5s ease",
            }}
          >
            {/* Centered logo */}
            <Stack
              direction={"row"}
              alignItems="center"
              spacing={1}
              m={{ sm: "0", md: "1em" }}
              sx={{
                transition: "opacity 0.5s ease",
              }}
            >
              <Box>
                <Link to={"/"}>
                  <img
                    src={lotusLogo}
                    alt="Lotus Clinic Logo"
                    width={isSmallScreen ? 45 : isMediumScreen ? 65 : 65}
                    style={{
                      height: "auto",
                      cursor: "pointer",
                      margin: "0 auto",
                    }}
                  />
                </Link>
              </Box>

              <Link
                to={"/"}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Typography
                  variant="h6"
                  color={"black"}
                  fontSize={{ xs: 14, md: 20, l: 20 }}
                  sx={{
                    ml: 1,
                    fontFamily: "Inria Serif, serif",
                  }}
                >
                  Lotus Skin Clinic & Cosmetic Center
                </Typography>
              </Link>
              <Typography
                variant="h6"
                color={"black"}
                sx={{
                  fontFamily: "Inria Serif, serif",
                }}
              ></Typography>
            </Stack>
            {/* navigation links */}
            {!isLaptop ? (
              <PhoneMenu />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { sm: 0, md: 1, l: 2 },
                }}
                color={"GrayText"}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <Typography
                    variant="body1"
                    px={"16px"}
                    py={"6px"}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    Home
                  </Typography>
                </Link>
                <MenuItem
                  aria-controls="services-menu"
                  aria-haspopup="true"
                  onMouseEnter={handleServicesMenuOpen}
                  onClick={(e) => handleMenuLinkClick(e, "/services")}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="body1" onMouseLeave={handleMenuClose}>
                    Services
                  </Typography>
                  <Menu
                    id="services-menu"
                    anchorEl={servicesAnchorEl}
                    open={Boolean(servicesAnchorEl)}
                    onClose={handleMenuClose}
                    onMouseLeave={handleMenuClose}
                    MenuListProps={{
                      onMouseLeave: handleMenuClose,
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={() => handleMenuClick("/services")}>
                      <Typography variant="body1">Skin Cancer</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuClick("/services", "cosmetics")}
                    >
                      <Typography variant="body1">Cosmetics</Typography>
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem
                  aria-controls="about-menu"
                  aria-haspopup="true"
                  onMouseEnter={handleAboutMenuOpen}
                  onMouseLeave={handleMenuClose}
                  onClick={(e) => handleMenuLinkClick(e, "/about-us")}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="body1">About</Typography>
                  <Menu
                    id="about-menu"
                    anchorEl={aboutAnchorEl}
                    open={Boolean(aboutAnchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      onMouseLeave: handleMenuClose,
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem onClick={() => handleMenuClick("/about-us")}>
                      <Typography variant="body1">Meet the Team</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleMenuClick("/about-us", "billing")}
                    >
                      <Typography variant="body1">Billing</Typography>
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/contact"
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="body1" onMouseLeave={handleMenuClose}>
                    Book Now
                  </Typography>
                </MenuItem>
                <Button
                  color="inherit"
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#000000",
                    color: "#FFFFFF",
                    maxheight: "50px",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                    },
                  }}
                  component="a"
                  href="/contact"
                >
                  <PhoneIcon sx={{ mr: 1, fontSize: "inherit" }} /> 07 5500 6608
                </Button>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default NavigationBar;
