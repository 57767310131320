import React from "react";

function GoogleMapComponent() {
  const url = `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJf73iEysQkWsRTxjV8zNfnxs&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  return (
    <iframe
      width="100%"
      height="100%"
      style={{ border: 0 }}
      loading="lazy"
      allowFullScreen
      src={url}
      title="googleMaps"
    ></iframe>
  );
}

export default React.memo(GoogleMapComponent);
