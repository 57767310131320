import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import CssBaseline from "@mui/material/CssBaseline";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import AboutUs from "./pages/AboutUs";
import { Box } from "@mui/material";

function App() {
  return (
    <div>
      <CssBaseline />
      <NavigationBar />
      <Box sx={{ backgroundColor: "#F0F0F0" }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </Box>
      <Footer />
    </div>
  );
}

export default App;
