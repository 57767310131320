import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  Link as MuiLink,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import storefront2 from "../assets/storefront2.png";
import storefront3 from "../assets/storefront3.png";
import doctor1 from "../assets/doctor1.png";
import doctor2 from "../assets/doctor2.png";
import InfoSection from "../components/InfoSection";

// Custom styled components
const ServiceCircle = styled(Paper)(({ theme }) => ({
  width: 100,
  height: 100,
  borderRadius: "50%",
  border: "2px solid black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: theme.spacing(3),
  textAlign: "center",
  [theme.breakpoints.up("xs")]: {
    width: 200,
    height: 200,
  },
  [theme.breakpoints.up("sm")]: {
    width: 125,
    height: 125,
  },
  [theme.breakpoints.up("md")]: {
    width: 150,
    height: 150,
  },
  [theme.breakpoints.up("lg")]: {
    width: 175,
    height: 175,
  },
}));

const serviceTextStyle = {
  whiteSpace: "normal",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "80%",
  display: "block",
  lineHeight: "1.2em", // Adjust as needed
  height: "2.4em", // Should be 2x lineHeight to ensure two lines
  fontWeight: "bold", // Make the text bold
  fontSize: "1.2rem", // Make the text larger
};

const DividingLine = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1185px",
        height: "1px",
        backgroundColor: "black",
        mb: 2,
        margin: "0 auto",
      }}
    />
  );
};

export default function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const services = [
    {
      name: "Anti-Wrinkle Injections",
      url: "/services?expand=anti-wrinkle",
      sectionId: "anti-wrinkle",
    },
    {
      name: "Dermal Filler",
      url: "/services?expand=dermal-filler",
      sectionId: "dermal-filler",
    },
    {
      name: "Non-Surgical Face Lifts",
      url: "/services?expand=face-lifts",
      sectionId: "face-lifts",
    },
    {
      name: "Eye lift",
      url: "/services?expand=eye-lift",
      sectionId: "eye-lift",
    },
    {
      name: "Sunspot Removal",
      url: "/services?expand=sunspot-removal",
      sectionId: "sunspot-removal",
    },
    {
      name: "Mole or skin tag removal",
      url: "/services?expand=mole-removal",
      sectionId: "mole-removal",
    },
  ];

  // Split services into two groups for left and right
  const leftServices = services.slice(0, 3);
  const rightServices = services.slice(3);

  interface Service {
    name: string;
    url: string;
    sectionId: string;
  }

  const handleServiceClick = (service: Service) => {
    navigate(service.url);
    setTimeout(() => {
      const sectionElement = document.getElementById(service.sectionId);
      const offset = 100; // Adjust this value to match the height of your navigation bar
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        window.scrollTo({
          top: targetScroll,
          behavior: "smooth",
        });
      }
    }, 100); // Delay to ensure the DOM has updated with the new content
  };

  const handleMeetTheTeamClick = () => {
    navigate("/about-us#meet-the-team");
    setTimeout(() => {
      const sectionElement = document.getElementById("meet-the-team");
      const offset = 100; // Adjust this value to match the height of your navigation bar
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        window.scrollTo({
          top: targetScroll,
          behavior: "smooth",
        });
      }
    }, 100); // Delay to ensure the DOM has updated with the new content
  };

  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          pb: 8,
          backgroundImage: `url(${storefront2})`, // Replace with your desired image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "flex-end", // Align items to the bottom
          justifyContent: "center",
          minHeight: "800px", // Adjust the height as needed
          color: "white", // Adjust text color for visibility against the background image
          textAlign: "center",
          mt: 0,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent background
              backdropFilter: "blur(10px)", // Apply blur
              borderRadius: "16px", // Rounded corners
              padding: "20px", // Padding inside the box
              display: "inline-block", // Shrink to fit content
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              fontWeight="bold"
              color="#333333" // Adjusted to a dark gray for better contrast
              sx={{
                fontFamily: "Inria Serif, serif", // Set the font family to Inria Serif
                letterSpacing: "0.1em", // Adds spacing between letters
                lineHeight: "1.3", // Adjusts the height between lines of text
              }}
            >
              YOUR BOUTIQUE SKIN CLINIC IN BIGGERA WATERS
            </Typography>
            <Button
              variant="contained"
              component={Link}
              to="/contact"
              sx={{
                backgroundColor: "#333333", // Soft, dark gray that appears nearly black
                color: "#FFFFFF", // White text color for contrast
                borderRadius: "0px", // No rounded edges
                padding: "6px 16px", // Default padding
                fontSize: "0.875rem", // Default font size
                "&:hover": {
                  backgroundColor: "#FFFFFF", // White background color
                  color: "#333333", // Black text color
                },
                // Responsive adjustments for larger screens
                "@media (min-width:600px)": {
                  fontSize: "1rem", // Larger font size on screens wider than 600px
                  padding: "10px 20px", // Increased padding for a bigger button appearance
                },
                "@media (min-width:960px)": {
                  fontSize: "1.125rem", // Even larger font size on screens wider than 960px
                  padding: "12px 24px", // Further increased padding for a bigger button appearance
                },
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Container>
      </Box>

      {/* Intro Section */}
      <Box
        sx={{
          py: 8,
          backgroundColor: "#121212", // A dark black background
          color: "white", // White text for contrast
          textAlign: "center", // Center the text inside the box
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            fontWeight="bold"
            sx={{
              mb: 3, // Margin bottom for spacing between title and paragraph
              fontFamily: "Montserrat, sans-serif", // Set the font family to Montserrat
              color: "#ECECEC", // Light grey color for the title text
            }}
          >
            Lotus Skin Clinic & Cosmetic Centre
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem", // Slightly larger font size for readability
              fontFamily: "Roboto, sans-serif", // Set the font family to Roboto
              color: "#ECECEC", // Same light grey color for the paragraph text
            }}
          >
            Discover the difference with our state-of-the-art facilities and
            experienced professionals, dedicated to perfecting your skincare
            routine and enhancing your natural beauty.
          </Typography>
        </Container>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: { xs: 2, sm: 8 } }}>
        <Grid item xs={12} sm={4} display={{ sm: "none" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              my: 4, // Margin for vertical spacing
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "black",
                textTransform: "uppercase",
                mb: 1, // Margin bottom for spacing below the title
              }}
            >
              Explore our clinic
            </Typography>
            <Box
              sx={{
                width: "50%",
                height: "1px",
                backgroundColor: "black",
                my: 1, // Margin for vertical spacing
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                color: "black",
                textTransform: "uppercase",
              }}
            >
              Take a look at our services
            </Typography>
          </Box>
        </Grid>
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* Left Services */}
            <Grid
              item
              xs={12}
              sm={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {leftServices.map((service, index) => (
                <Grid
                  item
                  key={service.name}
                  sx={{
                    alignSelf:
                      index === 0
                        ? "flex-end"
                        : index === leftServices.length - 1
                        ? "flex-end"
                        : "flex-start",
                  }}
                >
                  <MuiLink
                    component="button"
                    onClick={() => handleServiceClick(service)}
                    style={{ textDecoration: "none" }}
                  >
                    <ServiceCircle elevation={3}>
                      <Typography variant="caption" sx={serviceTextStyle}>
                        {service.name}
                      </Typography>
                      <ArrowForwardIcon
                        sx={{ color: "black", fontSize: "1rem", mt: 1 }}
                      />
                    </ServiceCircle>
                  </MuiLink>
                </Grid>
              ))}
            </Grid>

            {/* Central Title and Subtitle */}
            <Grid item xs={12} sm={4} display={{ xs: "none", sm: "unset" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  my: 4, // Margin for vertical spacing
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    color: "black",
                    textTransform: "uppercase",
                    mb: 1, // Margin bottom for spacing below the title
                  }}
                >
                  Explore our clinic
                </Typography>
                <Box
                  sx={{
                    width: "50%",
                    height: "1px",
                    backgroundColor: "black",
                    my: 1, // Margin for vertical spacing
                  }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  Take a look at our services
                </Typography>
              </Box>
            </Grid>

            {/* Right Services */}
            <Grid
              item
              xs={12}
              sm={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {rightServices.map((service, index) => (
                <Grid
                  item
                  key={service.name}
                  sx={{
                    alignSelf:
                      index === 0
                        ? "flex-start"
                        : index === rightServices.length - 1
                        ? "flex-start"
                        : "flex-end",
                  }}
                >
                  <MuiLink
                    component="button"
                    onClick={() => handleServiceClick(service)}
                    style={{ textDecoration: "none" }}
                  >
                    <ServiceCircle elevation={3}>
                      <Typography variant="caption" sx={serviceTextStyle}>
                        {service.name}
                      </Typography>
                      <ArrowForwardIcon
                        sx={{ color: "black", fontSize: "1rem", mt: 1 }}
                      />
                    </ServiceCircle>
                  </MuiLink>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Who we are section */}
      <Box
        sx={{
          py: 8,
          backgroundColor: "#f0f0f0",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {/* Right Text and Button */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={3}
                sx={{
                  p: 4,
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                }}
              >
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    mb: 2,
                  }}
                >
                  Who We Are
                </Typography>
                <Box
                  sx={{
                    width: "50px",
                    height: "1px",
                    backgroundColor: "black",
                    margin: "0 auto",
                    mb: 2,
                  }}
                />
                <Typography variant="body1" sx={{ mb: 2 }}>
                  At Lotus Skin Clinic and Cosmetic Centre, we offer a great
                  range of services such as skin cancer checks and healthcare
                  advice, cosmetic skin care and treatments, and a great range
                  of quality skin care products for daily use. We strive to
                  provide you with personalized services addressing your
                  skincare concerns with treatments that you feel comfortable
                  with.
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                  Check out our services for more information. At Lotus Skin
                  Clinic and Cosmetic Center, we take each and every skin cancer
                  check that we conduct seriously.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#333333", // Dark gray background color
                    color: "#FFFFFF", // White text color
                    borderRadius: "0px", // No rounded edges
                    padding: "6px 16px", // Padding
                    fontSize: "0.875rem", // Font size
                    textTransform: "uppercase",
                    width: "fit-content",
                    mx: "auto",
                    display: "block",
                    "&:hover": {
                      backgroundColor: "#FFFFFF", // White background color
                      color: "#333333", // Black text color
                    },
                    // Responsive adjustments for larger screens
                    "@media (min-width:600px)": {
                      fontSize: "1rem", // Larger font size on screens wider than 600px
                      padding: "10px 20px", // Increased padding for a bigger button appearance
                    },
                    "@media (min-width:960px)": {
                      fontSize: "1.125rem", // Even larger font size on screens wider than 960px
                      padding: "12px 24px", // Further increased padding for a bigger button appearance
                    },
                  }}
                  onClick={handleMeetTheTeamClick}
                >
                  OUR TEAM
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/* Background Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${storefront3})`, // Replace with your desired image URL
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
            opacity: 0.5, // Adjust opacity as needed
          }}
        />
      </Box>

      {/* MEET THE DOCTORS section */}
      <Grid>
        <Grid item>
          <Box
            sx={{
              pt: 8,
              backgroundColor: "#f0f0f0",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Container maxWidth="lg">
              <Container maxWidth="md" sx={{ textAlign: "center" }}>
                <Typography
                  variant="h3"
                  gutterBottom
                  fontWeight="bold"
                  color="grey.700"
                >
                  MEET THE DOCTORS
                </Typography>
                <DividingLine />
              </Container>
            </Container>
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              py: 5,
              backgroundColor: "#f0f0f0",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Container maxWidth="lg">
              <InfoSection
                image={doctor2}
                title="Dr Gamal El-Ghobashy"
                subtitle="Clinic Owner and Director"
                qualifications="MB,BCH, DIPOBS, RNZCOG, DPP (DERMATOLOGY) (UK), FRACGP"
                descriptions={[
                  "Dr El-Ghobashy is an experienced physician and brings a wealth of international experience to the surgery, having worked previously in New Zealand and Egypt.",
                  "As a fellow of the Royal College of General Practitioners, Dr El-Ghobashy has worked in Australia for close to two decades. He is certified with the American License Board (USMLE) and is a member of the Skin Cancer Society (Australia).",
                  "Dr El-Ghobashy can offer astute surgical and medical solutions for your general health, skin care and cosmetic needs.",
                ]}
                buttonText="Meet the Team"
                daysAvailable="Monday - Friday"
                onButtonClick={handleMeetTheTeamClick}
              />
            </Container>
          </Box>
        </Grid>
        <DividingLine />
        <Grid item>
          <Box
            sx={{
              py: 5,
              backgroundColor: "#f0f0f0",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Container maxWidth="lg">
              <InfoSection
                image={doctor1}
                title="DR. TIMOTHY IBRAHIM"
                subtitle="DOCTOR - SKIN CARE SPECIALLIST"
                qualifications="Masters of Skin Cancer (UQ)"
                descriptions={[
                  "Dr. Timothy Ibrahim is a highly skilled vocationally registered skin cancer doctor with a diverse background in general practice and surgery. With over 8000+ skin checks conducted in a region known for its high concentration of skin cancer cases, Dr. Ibrahim brings extensive experience to his practice.",
                  "Having diagnosed and treated hundreds of skin cancers, Dr. Ibrahim provides comprehensive care to his patients. He obtained his Bachelor of Medicine and Bachelor of Surgery from the University of Adelaide and completed his general practice training at the Royal Australian College of General Practitioners.",
                  "Dr. Ibrahim holds an advanced clinical certificate in skin cancer medicine and surgery, further enhancing his expertise in the field. With his wealth of experience and commitment to excellence, patients can trust Dr. Timothy Ibrahim to deliver personalized and effective skin cancer care.",
                ]}
                daysAvailable="Wednesday, Thursday, Saturday"
                buttonText="Meet the Team"
                onButtonClick={handleMeetTheTeamClick}
              />
            </Container>
          </Box>
        </Grid>
      </Grid>

      {/* END */}

      {/* HOW WE KEEP YOUR SKIN HEALTHY & NATURAL */}
      <Box
        sx={{
          pt: 8,
          pb: 8,
          backgroundColor: "#121212", // A dark black background
          color: "white", // White text for contrast
          textAlign: "center", // Center the text inside the box
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h4"
            gutterBottom
            fontWeight="bold"
            sx={{
              mb: 2, // Margin bottom for spacing between title and paragraph
              fontFamily: "Montserrat, sans-serif", // Set the font family to Montserrat
              color: "#ECECEC", // Light grey color for the title text
              maxWidth: "75%",
              margin: "0 auto",
            }}
          >
            DID YOU KNOW?
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "white",
              mb: 1,
              mt: 1,
            }}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem", // Slightly larger font size for readability
              fontFamily: "Roboto, sans-serif", // Set the font family to Roboto
              color: "#ECECEC", // Same light grey color for the paragraph text
              mb: 4,
            }}
          >
            Approximately 2 out of every 3 Australians will receive a skin
            cancer diagnosis by the age of 70. Melanoma, the most common cancer
            among young Australians aged 15-39, affects around 2,500 Australians
            aged 25-49 each year.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem", // Slightly larger font size for readability
              fontFamily: "Roboto, sans-serif", // Set the font family to Roboto
              color: "#ECECEC", // Same light grey color for the paragraph text
              mb: 4,
            }}
          >
            Early detection is crucial, with over 95% of skin cancers being
            treatable if caught in time, including 90% of melanomas. Regular
            skin cancer checks are essential for ensuring early detection.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.1rem", // Slightly larger font size for readability
              fontFamily: "Roboto, sans-serif", // Set the font family to Roboto
              color: "#ECECEC", // Same light grey color for the paragraph text
            }}
          >
            At Lotus Skin Clinic, we prioritize early and comprehensive
            full-body skin checks. Our clinic is staffed with both male and
            female skin cancer doctors for your convenience.
          </Typography>
          <Button
            variant="contained"
            href="/contact"
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#333333",
              borderRadius: "15px",
              padding: "12px 24px",
              fontSize: "1rem",
              textTransform: "uppercase",
              width: "fit-content",
              mx: "auto",
              mt: 4,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#333333", // Black background color
                color: "#FFFFFF", // White text color
              },
            }}
          >
            BOOK NOW
          </Button>
        </Container>
      </Box>

      {/* Skincare Products Section */}
      {/* <Box sx={{ py: 8 }}>
        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            gutterBottom
            fontWeight="bold"
            color="grey.700"
          >
            SKINCARE PRODUCTS
          </Typography>
          <Box
            sx={{
              width: "70%",
              height: "1px",
              backgroundColor: "black",
              margin: "0 auto",
              mb: 2,
            }}
          />

          <Grid container spacing={4} justifyContent="center">
            {[...Array(9)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <MuiLink href={`#product-${index + 1}`} underline="none">
                  <Card
                    sx={{
                      transition: "box-shadow 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: 6, // Adds a shadow on hover
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image="https://placehold.co/200x200"
                      alt={`Skincare product ${index + 1}`}
                    />
                    <CardContent>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="textPrimary"
                      >
                        Product {index + 1}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Description of product {index + 1}.
                      </Typography>
                    </CardContent>
                  </Card>
                </MuiLink>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
    </>
  );
}
