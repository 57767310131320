import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Card,
  CardMedia,
} from "@mui/material";

interface InfoSectionProps {
  image?: string;
  title: string;
  subtitle?: string;
  qualifications?: string;
  descriptions: string[];
  daysAvailable?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  image,
  title,
  subtitle,
  qualifications,
  descriptions,
  daysAvailable,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Grid container spacing={4} alignItems="center">
      {/* Left Text and Button */}
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            borderRadius: "16px",
            backgroundColor: "#ffffff",
            textAlign: "left",
            height: "100%", // Ensure the Paper component takes the full height
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minWidth: "100%",
          }}
        >
          <Box minWidth={"100%"}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                textTransform: "uppercase",
                color: "#333",
              }}
            >
              {title}
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{
                textTransform: "uppercase",
                color: "#333",
              }}
              variant="subtitle1"
            >
              {subtitle}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "black",
                mb: 2,
              }}
            />
            <Typography variant="subtitle2" sx={{ mb: 2, color: "#777" }}>
              {qualifications}
            </Typography>
            {descriptions.map((desc: any, index: any) => (
              <Typography
                key={index}
                variant="body1"
                sx={{ mb: 2, color: "#333" }}
              >
                {desc}
              </Typography>
            ))}
          </Box>
          <Typography
            display={daysAvailable !== undefined ? "unset" : "none"}
            pb={2}
            sx={{
              color: "#333",
            }}
            variant="subtitle1"
          >
            <strong>Days Available:</strong> {daysAvailable}
          </Typography>
          {buttonText !== undefined && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#333333", // Dark gray background color
                color: "#FFFFFF", // White text color
                borderRadius: "0px", // No rounded edges
                padding: "12px 24px", // Padding to match the provided image
                fontSize: "1rem", // Font size to match the provided image
                textTransform: "uppercase",
                width: "fit-content",
                mx: "auto",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#FFFFFF", // White background color
                  color: "#333333", // Black text color
                },
              }}
              onClick={onButtonClick}
            >
              {buttonText}
            </Button>
          )}
        </Paper>
      </Grid>
      {/* Right Image */}
      {image !== undefined && (
        <Grid
          item
          xs={12}
          md={6}
          sx={{ borderRadius: "16px", maxHeight: "800px", overflow: "hidden" }}
        >
          <Card sx={{ height: "100%", borderRadius: "inherit" }}>
            <CardMedia
              component="img"
              image={image}
              alt={title}
              sx={{
                margin: "0 auto",
                borderRadius: "inherit",
              }}
            />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default InfoSection;
