import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import lotusLogo from "../assets/lotusLogo.png";

function Footer() {
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "flex-start",
            textAlign: { xs: "center", md: "left" },
            justifyContent: "center",
            gap: { sm: 0, md: 10 },
            mt: 2,
            mb: 2,
          }}
        >
          {/* Logo and Contact Information */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              mb: { xs: 4, md: 0 },
              maxWidth: { md: "50%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: { xs: 2, md: 0 },
                mr: { md: 4 },
              }}
            >
              <img
                src={lotusLogo}
                alt="Lotus Clinic Logo"
                style={{
                  width: "5em",
                  height: "auto",
                  cursor: "pointer",
                  marginBottom: "1em",
                }}
              />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Lotus Skin Clinic
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                &
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Cosmetic Center
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
                alignSelf: "center",
                gap: "1.2em",
                maxWidth: { md: "50%" },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <PhoneIcon sx={{ mr: 1, fontSize: 30 }} />
                <Typography variant="body1">07 5500 6608</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <EmailIcon sx={{ mr: 1, fontSize: 30 }} />
                <Typography variant="body1">
                  admin@lotusskinclinic.com.au
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <LocationOnIcon sx={{ mr: 1, fontSize: 30 }} />
                <Link
                  href="https://www.google.com/maps/search/?api=1&query=Shop+61%2F2a+HarbourTown+Shopping+Centre%2C+Biggera+Waters%2C+QLD+4216"
                  color="inherit"
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <Typography variant="body1">
                    Shop 61/2a HarbourTown Shopping Centre, Biggera Waters, QLD
                    4216
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>

          {/* Quick Links */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: { xs: "center", md: "flex-start" },
              alignSelf: "center",
              gap: "1.2em",
              maxWidth: { md: "50%" },
            }}
          >
            <Box sx={{ mb: { xs: 4, md: 0 } }}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Link href="/" color="inherit" underline="hover">
                Home
              </Link>
              <br />
              <Link href="/services" color="inherit" underline="hover">
                Services
              </Link>
              <br />
              <Link href="/contact" color="inherit" underline="hover">
                Bookings
              </Link>
              <br />
              <Link href="/about-us" color="inherit" underline="hover">
                About Us
              </Link>
            </Box>

            {/* Hours and Socials */}
            <Box>
              <Typography variant="h6" gutterBottom>
                Hours
              </Typography>
              <Typography variant="body1">Mon-Fri: 8AM - 4PM</Typography>
              <Typography variant="body1">Sat: 8AM - 2PM</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Socials
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    gap: 1,
                  }}
                >
                  <IconButton
                    color="inherit"
                    href="https://www.facebook.com/lotusskinclinicandcosmetic/ "
                    aria-label="Facebook"
                  >
                    <FacebookIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    href="https://www.instagram.com/lotusskincancerclinic/ "
                    aria-label="Instagram"
                  >
                    <InstagramIcon sx={{ fontSize: 30 }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
