import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import GoogleMapComponent from "../components/GoogleMap";

const DividerLine: React.FC = () => (
  <Box
    sx={{
      width: "50px",
      height: "3px",
      backgroundColor: "black",
      margin: "0 auto",
      my: 2,
    }}
  />
);

function ContactPage() {
  return (
    <Box sx={{ py: 4, pt: 12 }}>
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        {/* Title Section */}
        <Box sx={{ mb: 8, textAlign: "center" }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Contact Us
          </Typography>
          <DividerLine />
        </Box>

        {/* Content Section */}
        <Card sx={{ p: 2, mb: 4 }}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box>
                  <Typography variant="body1" sx={{ mb: 2, color: "#333" }}>
                    Contact the experienced and caring team at Lotus Skin Clinic
                    and Cosmetic Centre to arrange for your regular skin check
                    or cosmetic skin treatments.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2, color: "#333" }}>
                    Our team are looking forward to answering your questions and
                    enquiries and commence your journey to better skin. Our
                    friendly and experienced doctors will ensure that you
                    receive the right advice, treatment and required referrals.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2, color: "#333" }}>
                    Contact our office today by phoning on{" "}
                    <strong>(07) 5500 6608</strong> or fill out the online form
                    below. One of our team will be in touch with you shortly to
                    answer your enquiry.
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2, color: "#333" }}>
                    You can also book an appointment using the ‘Hot Doc’ app.
                    Follow us on social media too.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <GoogleMapComponent />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box sx={{ mt: 8, mb: 4, textAlign: "center" }}>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            Book Now
          </Typography>
          <DividerLine />
        </Box>
        <Card sx={{ p: 2, mb: 4 }}>
          <CardContent>
            <iframe
              src="https://www.hotdoc.com.au/medical-centres/biggera-waters-QLD-4216/lotus-skin-clinic-and-cosmetic-centre1/doctors?wp=w_iframe"
              data-hotdoc-widget="iframe"
              width="100%"
              height="800"
              frameBorder="0"
              title="HotDoc"
            ></iframe>
          </CardContent>
        </Card>

        {/* Email Inquiry Section */}
        {/* <Card sx={{ p: 2 }}>
          <CardContent>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Send an Email Inquiry
              </Typography>
              <DividerLine />
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label="Email Body"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#333333",
                  color: "#FFFFFF",
                  borderRadius: "0px",
                  padding: "12px 24px",
                  fontSize: "1rem",
                  textTransform: "uppercase",
                  width: "fit-content",
                  mx: "auto",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#444444",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </CardContent>
        </Card> */}
      </Container>
    </Box>
  );
}

export default ContactPage;
